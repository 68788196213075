import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { type ButtonProps } from './Button.type';

export const Button = ({
  className,
  title,
  type = 'button',
  disabled,
  onClick,
  loading,
  leftIcon,
  rightIcon,
}: ButtonProps): JSX.Element => {
  return (
    <button
      className={className}
      type={loading ? 'button' : type}
      disabled={disabled ?? loading}
      onClick={onClick}
    >
      {loading && (
        <div style={{ marginRight: 5 }}>
          <TailSpin
            height={15}
            width={15}
            color="#7CB73F"
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            strokeWidth={4}
          />
        </div>
      )}
      {!loading && leftIcon === '' && (
        <div style={{ marginRight: 5 }}>{leftIcon}</div>
      )}
      {title}
      {rightIcon === '' && <div style={{ marginLeft: 5 }}>{rightIcon}</div>}
    </button>
  );
};
