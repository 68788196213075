import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { TextField, Password } from 'components/Input';
import { useTokenStore } from 'features/token/token.store';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Login = (): JSX.Element => {
  const nav = useNavigate();
  const { setToken } = useTokenStore();
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: () => {
      alert('submitted');
      setToken('im am token');
    },
  });

  return (
    <div className="h-[100vh] flex p-4">
      <div className="p-8 h-full w-5/12">
        <img
          src="https://cdn-hpbep.nitrocdn.com/HdGeOaZIWMPmGoBNHklvJlSjKTlutkbA/assets/images/optimized/rev-a4bd5d7/wp-content/uploads/2022/07/Lokshin.png"
          className="h-8"
        />
        <div className="h-full items-center flex justify-center">
          <div className="w-4/5">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-slate-600">
              Hey, Welcome back. Please enter your details to log in.
            </p>
            <div className="mt-10">
              <Form name="login-form" formik={formik}>
                <TextField name="email" label="Email" />
                <Password name="password" label="Password" />
                <div>
                  <Button
                    className="primary small w-full"
                    title="Login"
                    type="submit"
                    loading={formik.isSubmitting}
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div className="w-7/12 rounded-2xl p-8 text-white bg-auth-bg-1 bg-cover">
        <Button
          className="small bg-white border-white ml-auto !text-black"
          title="Register"
          onClick={() => {
            nav('/register');
          }}
          loading={false}
        />
      </div>
    </div>
  );
};
