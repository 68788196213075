import {
  Form as FormikForm,
  type FormikContextType,
  FormikProvider,
} from 'formik';
import { FormContext } from './context';
import React from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export const Form = (props: {
  [x: string]: unknown;
  formik: unknown;
  name: string;
}): JSX.Element => {
  const { formik, name, ...otherProps } = props;

  return (
    <FormikProvider value={formik as FormikContextType<unknown>}>
      <FormContext.Provider value={{ name }}>
        <FormikForm data-cy={name} {...otherProps} />
      </FormContext.Provider>
    </FormikProvider>
  );
};
