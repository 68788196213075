import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login, Register } from 'screens/auth';
import { Home } from 'screens/home/home.screen';

const Profile = (): JSX.Element => {
  return <div>Test Profile Page</div>;
};

export const Auth = [
  {
    path: '/login',
    Element: Login,
  },
  {
    path: '/register',
    Element: Register,
  },
];

export const ProtectedRoutes = [
  {
    path: '/profile',
    Element: Profile,
  },
  {
    path: '/',
    Element: Home,
  },
];

export const activeRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        {[...ProtectedRoutes, ...Auth].map((PR, index) => (
          <Route key={index} path={PR.path} element={<PR.Element />} />
        ))}
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};

const routes = activeRoutes();
export default routes;
