import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type PropertyStore } from './types';

const initialValues = {
  token: undefined,
};

export const useTokenStore = create<PropertyStore>()(
  immer((set, _get) => {
    return {
      ...initialValues,
      setToken: (token) => {
        set((draft) => {
          draft.token = token;
        });
      },
    };
  }),
);
