import { ApolloProvider } from '@apollo/client';
import { useApolloProvider } from './services/apollo';
import React from 'react';
import { routes } from './services/routes';
export const App = (): JSX.Element => {
  const client = useApolloProvider();

  return <ApolloProvider client={client}>{routes}</ApolloProvider>;
};

export default App;
