import { gql, useQuery } from '@apollo/client';
import { usePropertyStore } from 'features/property/property.store';
import React from 'react';

export const GET_ALL_ROOM = gql`
  query {
    getAllRooms {
      _id
      roomNumber
      user {
        _id
        firstName
        lastName
        email
        phoneNumber
        whatsappNumber
        type
        profilePicture
        status
        createdDate
        updatedDate
        active
      }
      rent
      deposit
      meterbox
      parking
      pets
      kids
      rentalType
      bathroomType
      availability
      description
      images
      status
      active
      size
      views
      createdDate
      updatedDate
    }
  }
`;

export const Home = (): JSX.Element => {
  const { loading, data } = useQuery(GET_ALL_ROOM);

  const { _id, setPropertyId } = usePropertyStore();
  return (
    <div id="home-screen">
      {loading ? <span data-testid="loading">Loading....</span> : <span></span>}
      {data?.getAllRooms?.map((property: { _id: string }) => (
        <div data-testid={`property-${property._id}`} key={property._id}>
          {property?._id}
        </div>
      ))}
      <h1>_id: {_id}</h1>

      <button
        onClick={() => {
          setPropertyId('8yriuwgefjrhvdeohifugjf');
        }}
      >
        add ID
      </button>
    </div>
  );
};
