import { Button } from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Register = (): JSX.Element => {
  const nav = useNavigate();
  return (
    <div className="h-[100vh] flex p-4">
      <div className="p-8 h-full w-5/12">
        <img
          src="https://cdn-hpbep.nitrocdn.com/HdGeOaZIWMPmGoBNHklvJlSjKTlutkbA/assets/images/optimized/rev-a4bd5d7/wp-content/uploads/2022/07/Lokshin.png"
          className="h-8"
        />
        <div className="h-full items-center flex justify-center">
          <div className="w-4/5">
            <h1 className="text-3xl font-bold">Create Account</h1>
            <p className="text-slate-600">
              Let`s get you started with your property management
            </p>
            <div className="mt-10">
              <div className="flex">
                <input
                  className="custom-input w-1/2 mr-2"
                  autoComplete="new-password"
                  placeholder="First Name"
                />
                <input
                  className="custom-input  w-1/2"
                  autoComplete="new-password"
                  placeholder="Last Name"
                />
              </div>
              <div className="flex">
                <input
                  className="custom-input w-1/2 mr-2"
                  autoComplete="new-password"
                  placeholder="Phone number"
                />
                <input
                  className="custom-input  w-1/2"
                  autoComplete="new-password"
                  placeholder="Whatsapp number"
                />
              </div>
              <input
                className="custom-input  w-1/2"
                autoComplete="new-password"
                placeholder="Email Address"
              />
              <div className="flex">
                <input
                  className="custom-input w-1/2 mr-2"
                  autoComplete="new-password"
                  placeholder="Password"
                />
                <input
                  className="custom-input  w-1/2"
                  autoComplete="new-password"
                  placeholder="Confirm Password"
                />
              </div>
              <div>
                <Button
                  className="primary small w-full"
                  title="Register"
                  type="submit"
                  loading={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-7/12 rounded-2xl p-8 text-white bg-auth-bg-1 bg-cover">
        <Button
          className="small bg-white border-white ml-auto !text-black"
          title="Login"
          onClick={() => {
            nav('/login');
          }}
          loading={false}
        />
      </div>
    </div>
  );
};
