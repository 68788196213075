import {
  ApolloClient,
  InMemoryCache,
  type NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';

export const useApolloProvider = (): ApolloClient<NormalizedCacheObject> => {
  let uploadLink = createUploadLink({
    uri: 'https://server.lokshinrentals.co.za',
  });

  const authLink = setContext((_, { headers }) => {
    // // get the authentication token from local storage if it exists
    return {
      headers: {
        ...headers,
        authorization: localStorage.getItem('token'),
      },
    };
  });

  uploadLink = authLink.concat(uploadLink);
  const wsLink = new WebSocketLink({
    uri: 'ws://server.lokshinrentals.co.za',
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: {
        authToken: localStorage.getItem('token'),
        headers: {
          authorization: localStorage.getItem('token'),
        },
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    uploadLink,
  );

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({ addTypename: false }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  });

  return client;
};
