import React from 'react';
import { StyledInputLabel } from './TextField.style';
import { useField } from 'formik';
import { type TextFieldProps } from './TextField.type';

export const TextField = ({
  label,
  type = 'text',
  ...props
}: TextFieldProps): JSX.Element => {
  const [field, meta] = useField(props as TextFieldProps);

  const keyboardType = (): 'text' | 'email' | 'numeric' | undefined => {
    if (type === 'number') {
      return 'numeric';
    }
    if (type === 'email') {
      return 'email';
    }
    return 'text';
  };

  return (
    <React.Fragment>
      <StyledInputLabel>{label} </StyledInputLabel>
      {type === 'textarea' ? (
        <textarea
          className="custom-input"
          rows={props.rows ?? 6}
          style={{
            padding: '10px 20px',
            borderColor: meta.touched && meta.error ? 'red' : '#e5e5e5',
          }}
          {...props}
          {...field}
        />
      ) : (
        <input
          className="custom-input"
          autoComplete="new-password"
          inputMode={keyboardType()}
          style={{
            borderColor: meta.touched && meta.error ? 'red' : '#e5e5e5',
          }}
          type={type}
          min={0}
          {...props}
          {...field}
        />
      )}
      {meta.touched && meta.error ? (
        <div className="input-error"> {meta.error} </div>
      ) : null}
    </React.Fragment>
  );
};
